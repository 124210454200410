<template>
    <div class="breadcrumbs">
        <div v-if="scroll > 0" class="breadcrumbs__gradient-left">
            <FormButton
                class="l-button--icon-only l-button--secondary l-button--size-30 flex-shrink-0 breadcrumbs__button"
                @click="scroll_left"
            >
                <IconsArrowLeft />
            </FormButton>
        </div>
        <div
            ref="breadcrumbs"
            @scroll="scrolling"
            class="d-flex flex-row breadcrumbs__content"
        >
            <div
                class="flex-shrink-0 breadcrumbs__item"
                v-for="(item, index) in items"
                :key="item"
            >
                <component
                    class="equal-small-1 breadcrumbs__link"
                    :class="{
                        'breadcrumbs__link--current':
                            index === Object.keys(items).length - 1,
                    }"
                    :is="
                        index != Object.keys(items).length - 1
                            ? NuxtLink
                            : 'div'
                    "
                    :to="item?.to"
                >
                    {{ item.text }}
                </component>
                <div
                    v-if="index != Object.keys(items).length - 1"
                    class="breadcrumbs__icon"
                >
                    <IconsWrapper size="14px">
                        <IconsArrowRight />
                    </IconsWrapper>
                </div>
            </div>
        </div>
        <div v-if="scroll < scrollMax" class="breadcrumbs__gradient-right">
            <FormButton
                class="l-button--icon-only l-button--secondary l-button--size-30 flex-shrink-0 breadcrumbs__button breadcrumbs__button--right"
                @click="scroll_right"
            >
                <IconsArrowRight />
            </FormButton>
        </div>
    </div>
</template>

<script setup>
import { NuxtLink } from '#components';
import { useJsonld } from '#jsonld';
import { ref, onMounted } from 'vue';

const props = defineProps({
    items: {
        type: Object,
        required: true,
    },
});

const breadcrumbs = ref(null);
const scroll = ref(0);
const scrollMax = ref(0);
const scrollAmount = ref(50);

onMounted(() => {
    // timeout in order to get correct clientWidth
    setTimeout(() => {
        scrollMax.value = Math.max(
            0,
            breadcrumbs.value.scrollWidth - breadcrumbs.value.clientWidth,
        );
        scroll.value = scrollMax.value;

        scrollAmount.value = Math.max(
            50,
            Math.round(breadcrumbs.value.clientWidth * 0.85) - 40,
        );

        breadcrumbs.value.scrollTo({
            left: scroll.value,
        });
    }, 200);
});

function scroll_left() {
    scroll.value = Math.max(0, scroll.value - scrollAmount.value);
    breadcrumbs.value.scrollTo({
        left: scroll.value,
        behavior: 'smooth',
    });
}

function scroll_right() {
    scroll.value = Math.min(scrollMax.value, scroll.value + scrollAmount.value);
    breadcrumbs.value.scrollTo({
        left: scroll.value,
        behavior: 'smooth',
    });
}

function scrolling() {
    scroll.value = breadcrumbs.value.scrollLeft;
}

if (process.client) {
    const breadCrumbList = [];
    props.items?.forEach(function (item, i) {
        breadCrumbList.push({
            '@type': 'ListItem',
            position: i,
            name: item.text,
            item: `https://${window.location.host}${item.to}`,
        });
    });
    useJsonld({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadCrumbList,
    });
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.breadcrumbs {
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    &__content {
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    &__item {
        display: flex;
    }

    &__link {
        text-align: left;
        color: $color-light;
        line-height: 30px;
        text-decoration: none;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover,
        &:focus,
        &:active {
            color: $color-disabled;
            transition: 0.3s ease;
        }

        &--current {
            color: $color-disabled;
            cursor: unset;
            transition: 0.3s ease;
        }
    }

    &__icon {
        display: inline-block;
        margin: auto 10px;

        :deep(svg) {
            fill: $color-disabled;
        }
    }

    &__button {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;

        &--right {
            top: 0;
            left: unset;
            right: 0;
        }

        border-color: $color-dark-02;
        background-color: $color-dark-02;
    }

    &__gradient-left {
        position: absolute;
        top: 0;
        left: -1px;
        right: 0;
        bottom: 0;
        height: 30px;
        width: 70px;
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.9) 50%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    &__gradient-right {
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        bottom: 0;
        height: 30px;
        width: 45px;
        background: linear-gradient(
            to left,
            rgba(0, 0, 0, 0.75) 0%,
            rgba(0, 0, 0, 0.75) 33%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}
</style>
